import styled from 'styled-components';
import themeConstants from 'web/styles/themeConstants';

const TwoColumnContainer = styled.div`
  margin-top: 16px;
  display: grid;

  grid-template: min-content min-content / auto;
  gap: 20px;

  ${themeConstants.media.md} {
    grid-template: auto / minmax(0, 1fr) minmax(0, 1fr);
  }
`;

const BloggingImageSquare = styled.div`
  padding-bottom: 100%;
  background: url(/signup-blogging.png) center/contain no-repeat;
`;

const TitleThin = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  line-height: 30px;
`;

const SubTitle = styled.div`
  margin-top: 16px;
  color: ${themeConstants.palette.gray[700]};
`;

export { BloggingImageSquare, TwoColumnContainer, SubTitle, TitleThin };
