import React from 'react';
import { Helmet } from 'react-helmet-async';
import CenterPageLayoutNew from 'web/components/CenterPageLayoutNew';
import ScreenTracker from 'web/components/ScreenTracker';
import BodyBackground from 'web/styles/BodyBackground';
import SignupRegular from './SignupRegular';

const SignupOld = () => {
  return (
    <>
      <Helmet title="Signup" />
      <ScreenTracker screenName="Signup" />
      <BodyBackground color="#FFEBE0" />
      <CenterPageLayoutNew>
        <SignupRegular />
      </CenterPageLayoutNew>
    </>
  );
};

const Signup = () => {
  return (
    <>
      <Helmet title="Signup" />
      <ScreenTracker screenName="Signup" />
      <BodyBackground color="#FFEBE0" />
      <CenterPageLayoutNew>
        <SignupRegular />
      </CenterPageLayoutNew>
    </>
  );
};

export { SignupOld };
export default Signup;
