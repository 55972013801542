import { FunctionsErrorCode, httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppCheck from 'web/components/FirebaseContext/useAppCheck';
import useAuth from 'web/components/FirebaseContext/useAuth';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from 'web/components/Spinner';
import TrackingContext from 'web/components/TrackingContext';
import RewardfulContext from 'web/components/TrackingContext/RewardfulContext';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';
import AccountForm, { FormValues as AccountFormValues } from './AccountForm';
import { signup } from './common';
import EmailSubscribed from './EmailSubscribed';

const SignupRegular = () => {
  const navigate = useNavigate();
  useAppCheck();
  const functions = useFunctions();
  const auth = useAuth();
  const tracking = useContext(TrackingContext);
  const errorReporter = useErrorReporter();
  const { user, loggingIn } = useContext(UserContext);
  const [step, setStep] = useState<'init' | 'account' | 'subscribed'>('init');
  const [formValues, setFormValues] = useState<AccountFormValues>(null);
  const [submitting, setSubmitting] = useState(false);
  const [accountError, setAccountError] = useState<string>(null);
  const rewardful = useContext(RewardfulContext);

  useEffect(() => {
    // Wait until we know that the user is not logged in
    if (loggingIn) {
      return;
    } else if (step === 'init' && !!user) {
      navigate('/home', { replace: true });
    } else if (step === 'init') {
      setStep('account');
    }
  }, [loggingIn, navigate, step, user]);

  const createAccount = async (values: AccountFormValues) => {
    setSubmitting(true);
    setAccountError(null);
    try {
      const rewardfulReferralId = rewardful?.current()?.referral;
      const uid = await signup(auth, functions, values, { rewardfulReferralId });
      tracking.trackEvent('Signed Up', { uid });
      navigate('/onboarding');
    } catch (err) {
      setSubmitting(false);
      const errorCode = err.code as `functions/${FunctionsErrorCode}`;
      if (errorCode === 'functions/failed-precondition') {
        setAccountError('An account with this email already exists');
      } else if (errorCode === 'functions/unauthenticated') {
        setAccountError('Cannot verify that you are not a bot. Please try again later, or try a different browser.');
        errorReporter.report(new Error(`AppCheck verification failed`));
      } else {
        setAccountError('Something went wrong. Please try again');
        errorReporter.report(new Error(`Failed to create an account: ${err}`));
      }
    }
  };

  const saveEmail = async (values: AccountFormValues) => {
    setStep('subscribed');
    try {
      const { country, ...restValues } = values;
      await httpsCallable(
        functions,
        'usersWaitlistJoin',
      )({
        ...restValues,
        country: country.value,
      });
      tracking.trackEvent('Lead Generated');
    } catch (err) {
      errorReporter.report(new Error(`Failed to add to waitlist: ${err}`));
    }
  };

  const onAccountSubmit = (values: AccountFormValues) => {
    setFormValues(values);
    if (values.country.unsupported) {
      saveEmail(values);
    } else {
      void createAccount(values);
    }
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {step === 'init' && (
        <div style={{ marginTop: '20px' }}>
          <Spinner />
        </div>
      )}
      {step === 'account' && (
        <AccountForm
          defaultValues={formValues}
          onSubmit={onAccountSubmit}
          submitting={submitting}
          error={accountError}
        />
      )}
      {step === 'subscribed' && (
        <EmailSubscribed firstName={formValues.firstName} email={formValues.email} country={formValues.country.label} />
      )}
    </div>
  );
};

export default SignupRegular;
