import { Functions, httpsCallable } from 'firebase/functions';
import { Auth, signInWithCustomToken } from 'firebase/auth';
import { FormValues as AccountFormValues } from './AccountForm';

type SignupRequest = {
  firstName: string;
  lastName: string;
  country: string;
  email: string;
  timezone: string;
};

type SignupResponse = {
  customToken: string;
};

type AccountMetadata = {
  rewardfulReferralId: string;
};

const signup = async (auth: Auth, functions: Functions, values: AccountFormValues, context?: AccountMetadata) => {
  const { firstName, lastName, email } = values;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const country = values.country.value;
  const rewardfulReferralId = context?.rewardfulReferralId;
  const res = await httpsCallable<SignupRequest, SignupResponse>(
    functions,
    'authSignup',
  )({
    firstName,
    lastName,
    country,
    email,
    timezone,
    ...(rewardfulReferralId ? { rewardfulReferralId } : {}),
  });
  if (!res.data.customToken) {
    throw new Error(`Signup function response doesn't contain a custom token`);
  }
  const credential = await signInWithCustomToken(auth, res.data.customToken);
  return credential.user.uid;
};

export { signup };
