import React, { useContext, useState } from 'react';
import { Controller, useForm, RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';
import { Button, FormError, FormGroup, Input, Label, LabelText, LinkStyled } from 'web/components/elements';
import { BloggingImageSquare, SubTitle, TwoColumnContainer } from 'web/components/login-signup';
import Spinner from 'web/components/Spinner';
import CountrySelect, { CountryOption, defaultCountry, getCountryByCode } from 'web/components/CountrySelect';
import GeoIpContex from 'web/components/GeoIpContext';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  country: CountryOption;
};

const NamesGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
`;

const Title = styled.h1`
  margin-top: 20px;
`;

const maxNameLength = 40;

const nameValidationRules: RegisterOptions = {
  required: 'Required',
  minLength: {
    value: 2,
    message: 'Too short, please use at least 1 character',
  },
  maxLength: {
    value: maxNameLength,
    message: `Too long, please limit the name to ${maxNameLength} characters`,
  },
  setValueAs: (v) => v.trim(),
};

const emailValidationRules: RegisterOptions = {
  required: 'Required',
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Invalid email address',
  },
  setValueAs: (v) => v.trim(),
};

const countryValidationRules: RegisterOptions = {
  required: 'Required',
};

const isCountrySupported = (country: CountryOption) => !country.unsupported;

const AccountForm = ({
  defaultValues,
  onSubmit,
  submitting,
  error,
  title,
}: {
  defaultValues: FormValues | null;
  onSubmit: (values: FormValues) => void;
  submitting: boolean;
  error: string | null;
  title?: string;
}) => {
  const geoIp = useContext(GeoIpContex);
  const [preSelectedCountry] = useState(() => getCountryByCode(geoIp?.countryCode) || defaultCountry);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm<FormValues>({
    defaultValues: defaultValues || {
      country: preSelectedCountry,
    },
  });
  const watchCountry = watch('country');
  return (
    <TwoColumnContainer>
      <div>
        <Title>{title || 'Join Introwise'}</Title>
        <SubTitle style={{ marginTop: 0, marginBottom: 16 }}>
          Already have an account? <LinkStyled to="/login">Login</LinkStyled>
        </SubTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <NamesGroup>
            <FormGroup>
              <Label>
                <Input
                  {...register('firstName', nameValidationRules)}
                  placeholder="Mary"
                  hasError={!!errors.firstName}
                  autoComplete="given-name"
                />
                <LabelText>First name</LabelText>
              </Label>
              {errors.firstName && <FormError>{errors.firstName.message}</FormError>}
            </FormGroup>
            <FormGroup>
              <Label>
                <Input
                  {...register('lastName', nameValidationRules)}
                  placeholder="Doe"
                  hasError={!!errors.lastName}
                  autoComplete="family-name"
                />
                <LabelText>Last name</LabelText>
              </Label>
              {errors.lastName && <FormError>{errors.lastName.message}</FormError>}
            </FormGroup>
          </NamesGroup>
          <FormGroup>
            <Label>
              <Input
                {...register('email', emailValidationRules)}
                placeholder="mary.doe@example.com"
                hasError={!!errors.email}
                autoComplete="email"
              />
              <LabelText>Email</LabelText>
            </Label>
            {errors.email && <FormError>{errors.email.message}</FormError>}
          </FormGroup>
          <FormGroup>
            <Label>
              <Controller
                name="country"
                defaultValue={defaultCountry}
                rules={countryValidationRules}
                control={control}
                render={({ field: { ref, ...props } }) => <CountrySelect {...props} />}
              />
              <LabelText>Country</LabelText>
            </Label>
          </FormGroup>
          {isCountrySupported(watchCountry) ? (
            <FormGroup>
              <small>
                By selecting Sign up below, I agree to Introwise&apos;s{' '}
                <LinkStyled to="/terms-of-service">Terms of Service</LinkStyled> and{' '}
                <LinkStyled to="/privacy-policy">Privacy Policy</LinkStyled>
              </small>
            </FormGroup>
          ) : (
            <>
              <FormGroup>
                Introwise is not yet available in {watchCountry.label}. Join the list to get notified when we launch in
                your country.
              </FormGroup>
              <FormGroup>
                <small>
                  By selecting Notify me below, I agree to Introwise&apos;s{' '}
                  <LinkStyled to="/privacy-policy">Privacy Policy</LinkStyled>
                </small>
              </FormGroup>
            </>
          )}
          <FormGroup>
            <Button type="submit" primary style={{ width: '100%' }} disabled={submitting}>
              {submitting && <Spinner />}
              {isCountrySupported(watchCountry) ? <span>Sign up</span> : <span>Notify me</span>}
            </Button>
          </FormGroup>
          <FormGroup>
            <FormError>{error || <>&nbsp;</>}</FormError>
          </FormGroup>
        </form>
      </div>
      <div>
        <BloggingImageSquare />
      </div>
    </TwoColumnContainer>
  );
};

export { FormValues };
export default AccountForm;
