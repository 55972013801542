import { useContext } from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import settings from 'web/utils/settings';
import { isClientSide } from 'web/utils/ssr';
import FirebaseContext from './FirebaseContext';

let appCheckPromise: Promise<void>;

const useAppCheck = () => {
  const firebase = useContext(FirebaseContext);
  const errorReporter = useErrorReporter();
  if (isClientSide && settings.appcheck.enabled && !appCheckPromise) {
    appCheckPromise = import('firebase/app-check')
      .then(({ initializeAppCheck, ReCaptchaV3Provider }) => {
        initializeAppCheck(firebase, {
          provider: new ReCaptchaV3Provider(settings.appcheck.recaptchaSiteKey),
          isTokenAutoRefreshEnabled: true,
        });
      })
      .catch((err) => errorReporter.report(err));
  }
};

export default useAppCheck;
