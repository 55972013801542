import React from 'react';
import styled from 'styled-components';
import { AnchorStyled } from 'web/components/elements';
import { TwoColumnContainer } from 'web/components/login-signup';

const Title = styled.h2`
  margin: 0;
  line-height: 30px;
  font-weight: bold;
`;

const EmailSubscribed = ({ firstName, email, country }: { firstName: string; email: string; country: string }) => (
  <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
    <div
      style={{
        height: 140,
        background: 'url(/signup-wiggles-top.png) center/contain no-repeat',
      }}
    />
    <TwoColumnContainer>
      <div>
        <Title>Thank you, {firstName}!</Title>
        <p>
          We&apos;ll send you a message to <b>{email}</b> once Introwise is available in {country}.<br /> If you have
          any questions feel free to ask us at{' '}
          <AnchorStyled href="mailto:contact@introwise.com">contact@introwise.com</AnchorStyled>
        </p>
      </div>
      <div>
        <div
          style={{
            height: '100%',
            minHeight: 140,
            background: 'url(/signup-waving.png) calc(50% + 5%) 50%/contain no-repeat',
          }}
        />
      </div>
    </TwoColumnContainer>
    <div
      style={{
        height: 140,
        background: 'url(/signup-wiggles-bottom.png) center/contain no-repeat',
      }}
    />
  </div>
);

export default EmailSubscribed;
